:root {
  --color-light: white;
  --color-dark: #212121;
  --color-signal: orange;
  
  --color-background: var(--color-light);
  --color-text: var(--color-dark);
  --color-accent: var(--color-signal);
  
  --size-bezel: .5rem;
  --size-radius: 4px;
  
  line-height: 1.4;
  
  color: var(--color-text);
  background: var(--color-background);
  padding: 0 calc(var(--size-bezel) * 3);
  margin-top: 30px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-weight: 600;
  line-height: 1;
  background-color: #f8f9fa;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.5rem;
  margin: 0.25em;
}

.input {
  position: relative;
}

.input__label {
  position: absolute;
  left: -150px;
  top: 0;
  padding: calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * .5);
  margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * .5);
  white-space: nowrap;
  transform: translate(0, 0);
  transform-origin: 0 0;
  background: var(--color-background);
  transition: transform 120ms ease-in;
  font-weight: bold;
  line-height: 1.2;
  cursor: text;
}

.input__field {
  box-sizing: border-box;
  display: block;
  width: 300px;
  border: 3px solid currentColor;
  padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
  color: currentColor;
  background: transparent;
  border-radius: var(--size-radius);
  font-size: 1em;
  margin: auto;
}

.input__field:focus + .input__label, .input__field:not(:placeholder-shown) + .input__label {
  transform: translate(0.25rem, -65%) scale(0.8);
  color: var(--color-accent);
}

/* Table styles */
table {
  border-collapse: separate;
  border-spacing: 0 20px;
  margin: auto;
}

th, td {
  padding: 0.5rem;
  margin-top: 2rem;
}

.row-style {
  border-top: 1px solid var(--color-dark);
}

.review {
  text-align: left;
  white-space: pre-wrap;
}

.mobile {
  display: none;
}

.about-context {
  text-align: left;
  padding: 0 16px;
}

h2.subtitle {
  color: #5f5e5e;
  font-size: .7em;
  margin-bottom: 10px;
}

.info-container {
  display: flex;
  flex-wrap: wrap;
}

.guide-content {
  text-align: left;
}

.guide-col {
  padding: 16px;
  box-sizing: border-box;
  width: 50%; /* Two columns side by side on wider screens */
}

/* Responsive styles */
@media screen and (max-width: 768px) {

  /* Reduce font size to fit more columns without scroll */
  table {
    font-size: 0.8em;
  }

  .about-section {
    font-size: 0.8em;
  }

  .info-section {
    font-size: 0.8em;
  }

  /* Reduce padding to fit table better */
  :root {
    padding: 0 .5rem;
  }

  /* Make the table cells wrap to the next line */
  td {
    white-space: normal;
    padding-left: 0;
    padding-right: 0;
  }

  /* Hide unnecessary columns on mobile */
  .dynamic-col {
    display: none;
  }

  /* Show mobile-specific elements */
  .mobile {
    display: inline;
  }

  /* Stack components on mobile */
  .info-container {
    flex-direction: column;
  }

  /* One column on smaller screens */
  .guide-col {
    width: auto;
  }
}
